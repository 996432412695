import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from './layout'
import { CardsSection, SimpleBanner, AreasSection } from '../components'
import useCourses from '../hooks/useCourses'
import useTrainingTypes from '../hooks/useTrainingTypes'
import PropTypes from 'prop-types'

const Area = ({ data, location }) => {
  const [q, setQ] = useState('')

  const area = data.allStrapiInterestArea.nodes[0]

  const dataCourses = useCourses()
  const fjsCourses = dataCourses?.allStrapiFjsCourses?.nodes

  const areaCourses = fjsCourses.filter((course) =>
    course.interest_areas.find((areaInt) => area.slug === areaInt.slug)
  )

  const newCourses = areaCourses.filter(
    (course) => course.dateStart >= new Date().toISOString()
  )

  const expiredCourses = areaCourses.filter(
    (course) => course.dateStart < new Date().toISOString()
  ).reverse()

  const dataTrainingTypes = useTrainingTypes()
  const trainingTypes = dataTrainingTypes.allStrapiTrainingType.nodes

  function filterCourses(courses) {
    if (q !== '') {
      return courses.filter((course) => course.training_type.slug === q)
    } else {
      return courses
    }
  }

  function capitalize(title) {
    console.log(title, "entro")
    const lower = title.toLowerCase()
    return title.charAt(0).toUpperCase() + lower.slice(1)
  }


  const trainingTypesFilter = location.pathname !== '/administracion-negocio' ? trainingTypes.filter(
    (trainingType) => trainingType.slug !== 'formacion-ejecutiva'
  ).map((trainingType, idx) => (
    <button
      key={`type-${idx}`}
      className={'btn-outline-darker'}
      onClick={() => {
        setQ(trainingType.slug)
      }}
    >
      {capitalize(trainingType.title)}
    </button>
  )) : trainingTypes.map((trainingType, idx) => (
    <button
      key={`type-${idx}`}
      className={'btn-outline-darker'}
      onClick={() => {
        setQ(trainingType.slug)
      }}
    >
      {capitalize(trainingType.title)}
    </button>
  ))   


  return (
    <Layout>
      <AreasSection />
      <SimpleBanner
        image={area?.banner}
        text={area?.title}
        icon={area?.icon[0]}
      />
      {areaCourses.length > 0 && (
        <div className="primary-background">
          <div className="container pt-5">
            <div className="row px-3 area-chip">
              {trainingTypesFilter}
              <button
                className={'btn-outline-darker'}
                onClick={() => {
                  setQ('')
                }}
              >
                Ver todos
              </button>
            </div>
          </div>
          <CardsSection courses={filterCourses(newCourses)} areas={true} id={'courses'}/>
          {expiredCourses.length > 0 && (
            <CardsSection
              courses={expiredCourses}
              related={true}
              title={'Cursos comenzados'}
              id={'cursos-comenzados'}
            />
          )}
        </div>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    allStrapiInterestArea(filter: { slug: { eq: $slug } }) {
      nodes {
        slug
        title
        id
        icon {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        banner {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

Area.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
}

export default Area
